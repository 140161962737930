import { inject, Injectable, signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { AuthService } from '@auth/services/auth.service';
import { ApiService } from '@shared/services/api.service';
import { map } from 'rxjs';

export type Assignment = {
  title: string;
};

type AssignmentResponse = {
  items: Assignment[];
  total: number;
};

@Injectable({
  providedIn: 'root',
})
export class AssignmentService {
  #api = inject(ApiService);
  #auth = inject(AuthService);

  userId = signal<string | undefined>(this.#auth.currentUserId());

  assignments = rxResource({
    request: this.userId,
    loader: ({ request: id }) =>
      this.#api.get<AssignmentResponse>(`coach/${id}/assignments`).pipe(map(response => response.items)),
  });
}
